<template>
    <div class="flex align-items-center mb-3">
        <div class="surface-card p-4 shadow-2 border-round w-full">
            <div>
                <Photo/>
            </div>
        </div>
    </div>
</template>

<script>
import CountryService from '../service/CountryService';
import UserService from '../service/UserService';
import Photo from './C_Photo.vue';

export default {
    components: {Photo}
}
</script>
<style scoped>
</style>
